var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"channel-home"}},[_c('v-parallax',{attrs:{"height":"230","src":"https://cdn.vuetifyjs.com/images/parallax/material.jpg"}}),_c('div',{staticClass:"nav-bgcolor"},[_c('div',{attrs:{"id":"channel-header"}},[_c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5","lg":"5","offset-md":"1"}},[_c('v-skeleton-loader',{staticClass:"mr-1",attrs:{"type":"list-item-avatar-two-line","loading":_vm.loading}},[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-avatar',{attrs:{"size":"80"}},[(_vm.channel.photoUrl !== 'no-photo.jpg')?_c('v-img',{attrs:{"src":(_vm.url + "/uploads/avatars/" + (_vm.channel.photoUrl))}}):_c('v-avatar',{attrs:{"color":"red","size":"60"}},[_c('span',{staticClass:"white--text headline "},[_vm._v(" "+_vm._s(_vm.channel.channelName.split('')[0].toUpperCase()))])])],1),_c('v-list-item-content',{staticClass:"align-self-auto"},[_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v(_vm._s(_vm.channel.channelName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.channel.subscribers)+" subscribers ")])],1)],1)],1)],1)],1),(!_vm.loading)?_c('v-col',{attrs:{"cols":"12","sm":"5","md":"3","lg":"3"}},[(_vm.currentUser && _vm.channel._id !== _vm.currentUser._id)?_c('v-btn',{class:[
                { 'red white--text': !_vm.subscribed },
                { 'grey grey--text lighten-3 text--darken-3': _vm.subscribed },
                'mt-6'
              ],attrs:{"tile":"","large":"","depressed":"","loading":_vm.subscribeLoading},on:{"click":_vm.subscribe}},[_vm._v(_vm._s(!_vm.subscribed ? 'subscribe' : 'subscribed'))]):(_vm.showSubBtn)?_c('v-btn',{class:[
                { 'red white--text': !_vm.subscribed },
                { 'grey grey--text lighten-3 text--darken-3': _vm.subscribed },
                'mt-6'
              ],attrs:{"tile":"","large":"","depressed":"","loading":_vm.subscribeLoading},on:{"click":_vm.subscribe}},[_vm._v(_vm._s(!_vm.subscribed ? 'subscribe' : 'subscribed'))]):_vm._e()],1):_vm._e()],1)],1)],1),_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-tabs',{attrs:{"background-color":"transparent","show-arrows":"","centered":"","center-active":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item,i){return _c('v-tab',{key:i},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c('v-container',{attrs:{"fluid":""}},[_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Uploads")]),_c('v-slide-group',{staticClass:"pa-4",attrs:{"multiple":"","show-arrows":""}},_vm._l((_vm.loading ? 5 : _vm.videos.data),function(video,i){return _c('v-slide-item',{key:i},[_c('v-skeleton-loader',{staticClass:"mr-1",attrs:{"type":"card-avatar","loading":_vm.loading,"width":"250px"}},[_c('video-card',{attrs:{"card":{ maxWidth: 250, type: 'noAvatar' },"video":video,"channel":video.userId}})],1)],1)}),1)],1)],1),_c('v-tab-item',[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Uploads")]),_c('v-row',_vm._l((_vm.loading ? 10 : _vm.videos.data),function(video,i){return _c('v-col',{key:i,staticClass:"mx-xs-auto",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-skeleton-loader',{attrs:{"type":"card-avatar","loading":_vm.loading}},[_c('video-card',{attrs:{"card":{ maxWidth: 350 },"video":video,"channel":video.userId}})],1)],1)}),1)],1)],1)],1)],1)],1)],1),_c('signin-modal',{attrs:{"openModal":_vm.signinDialog,"details":_vm.details},on:{"closeModal":function($event){_vm.signinDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }